/* Add this style in your CSS or SCSS file */
.custom-border {
    border: 1px solid black !important;
  }
  
  .tooltip-container {
    position: relative;
    display: inline-block;
    
  }
  
  .tooltip-text {
    visibility: hidden;
    background-color: #e2e2e2;
    color: black;
    text-align: center;
    
    padding: 5px 0;
    border-radius: 6px;
    border:  1px solid black;
    position: absolute;
    z-index: 1;
    bottom: 100%; /* Position the tooltip above the text */
    left: 50%;
    margin-left: -60px; /* Use half of the width to center the tooltip */
    width: 120px;
  }
  
  .tooltip-container:hover .tooltip-text {
    visibility: visible;
    
  }
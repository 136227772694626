

button {
  font-size: inherit;
  font-family: inherit;
}

.error-msg {
  color: hsl(0, 100%, 67%);
}

.comments-title {
  margin-bottom: .5rem;
}

.comment-form-row {
  display: flex;
  gap: .5rem;
}

.message-input {
  flex-grow: 1;
  resize: none;
  height: 70px;
  border-radius: .5em;
  padding: .5em;
  font-size: inherit;
  font-family: inherit;
  border: 2px solid  hsl(240, 8%, 85%);;
  line-height: 1.4;
}

.message-input:focus {
  border-color: hsl(225, 3%, 25%);;
  outline: none;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-1 {
  margin-top: .25rem;
}

.comment-stack {
margin:.3rem 0;
  
}

.comment-stack:last-child {
  margin-bottom: 0;
}

.nested-comments {
  padding-left: .8rem;
  flex-grow: 1;
}

.nested-comments-stack {
  display: flex;

}
.collapse-line {
  border: none;
  background: none;
  padding: 0;
  width: 20px;
  position: relative;
  cursor: pointer;
  outline: none;
  transform: translateX(-50%);
}

.collapse-line:hover::before,
.collapse-line:focus-visible::before {
  background-color: hsl(231, 82%, 6%);
}

.collapse-line::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 60%;
  width: 2px;
  background-color: hsl(231, 82%, 6%);
  transition: background-color 100ms ease-in-out;
}

/* Add an arrow towards the profile pic */
.collapse-line::after {
  content: "";
  position: absolute;
  top: 0%;
  left: 0;
  right: 0;
  transform: translate(250%, 250%) rotate(140deg); /* Adjusted rotate property */
  width: 8px;
  height: 8px;
  border-left: 2px solid hsl(231, 82%, 6%);
  border-top: 2px solid hsl(231, 82%, 6%); /* Adjusted border property */
  transition: border-color 100ms ease-in-out;
}

/* Adjust arrow on hover or focus */
.collapse-line:hover::after,
.collapse-line:focus-visible::after {
  border-left-color: hsl(231, 82%, 6%);
  border-top-color: hsl(231, 82%, 6%); /* Adjusted border property */
}




.hide {
  display: none;
}

.btn {
  --hue: 235;
  --color: hsl(var(--hue), 100%, 67%);
  padding: .5em 1em;
  background: var(--color);
  border: none;
  color:white;
  border-radius: .5em;
  font-size: .75em;
  cursor: pointer;
}
.opacity{
  opacity: 0.4;
}

.btn:hover,
.btn:focus-visible {
  --color: hsl(var(--hue), 100%, 74%);
}

.btn.icon-btn {
  background: none;
  color: var(--color);
  padding: .25em;
  display: flex;
  align-items: center;
}

.mr-1 {
  margin-right: .25em;
}

.icon-btn:hover,
.icon-btn:focus-visible {
  --color: hsl(var(--hue), 100%, 74%);
}

.icon-btn-active,
.icon-btn.danger {
  --hue: 0;
}

.icon-btn-active {
  position: relative;
}

.icon-btn-active::before {
  content: "\00D7";
  position: absolute;
  font-size: .75em;
  width: 1em;
  height: 1em;
  color: white;
  background-color: var(--color);
  border-radius: 50%;
  bottom: .1em;
  right: .1em;
}

.comment {
  border-radius: .5rem;
  padding:0.2rem;
}

.comment .header {
  color: rgb(47, 30, 203);
  display: flex;
  justify-content: space-between;
  margin-bottom: .35rem;
  font-size: .75em;
  align-items: center;

}

.comment .header .name {
  font-weight: bold;
  font-size: .95rem;
  color: black;
}

.comment .message {
  white-space: pre-wrap;
  margin:0 2rem;
  font-weight: 500;
  font-size: 1.1rem;
}

.comment .footer {
  display: flex;
  gap: .5rem;
  margin-top: .5rem;
}

.ml-3 {
  margin-left: 1.5rem;
}

.btn[disabled] {
  --color: hsl(var(--hue), 20%, 74%);
}

.message-and-vote-buttons {
  display: flex;
  align-items: center;
}

.vote-buttons {
  margin-left: 1%;
  display:flex;
}

.comment-content {
  display: flex;
  flex-direction: column;
}
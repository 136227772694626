*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body{
    height:100%;
    background-image: url("./assets/background.jpg") ;
    background-position:center ;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
}
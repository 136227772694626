/* contactUs.css */

body {
    background-color: #f8f9fa;
    font-family: 'Arial', sans-serif;
}

.contact-us-wrapper {
    text-align: center;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.h1heading {
    color: #007bff;
    margin-bottom: 20px;
}

.contact-us-container {
    max-width: 800px;
    display: flex;
    flex-direction: column;
}

.company-info,
.placement-center {
    width: 100%;
    padding: 20px;
    border-top: 1px solid #ccc;
}

.company-info h2,
.placement-center h3 {
    color: #007bff;
    margin-bottom: 10px;
    font-size: 24px;
}

.company-info p,
.placement-center p {
    margin: 10px 0;
    font-size: 16px;
}

.company-info .title,
.placement-center .title {
    font-weight: bold;
    color: #333;
}
